import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { genrateRoutes } from './routes';
import withStyles from '@mui/styles/withStyles';
import UserContext from './UserContext';
import NavbarContext from './NavbarContext';
import NavigationBar from './NavigationBar';
import SideNav from './SideNav';
import { isXLarge } from './utils/Device';
import './index.css';
import './components/help/OnlineHelp.css';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import BuildsimpleIcon from './components/icon/Buildsimple';
import { getFeaturesToShow, getFeaturesToHide } from './data/Organization';
import { withNavAndSideBar } from './utils/withRouterRef';
import { configureAmplify } from './data/Base';
import { injectIntl } from 'react-intl';

const drawerWidth = 250;

const styles = theme => ({
  toolbarSpacer: {
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    paddingTop: 0
  }
});

class App extends React.Component {

  static propTypes = {
    authState: PropTypes.string,
    classes: PropTypes.object,
    hasVerifier: PropTypes.bool,
    setSelectedProfile: PropTypes.func,
    intl: PropTypes.object
  };

  constructor (props) {
    super(props);

    this.state = {
      open: false,
      shift: true,
      showAbout: false,
      initialLogin: true // TODO: This must be saved for the user
    };

    this.handleMenuButtonPressed = this.handleMenuButtonPressed.bind(this);
    this.wizardClose = this.wizardClose.bind(this);
    this.navBarRef = React.createRef();
    this.sideNavRef = React.createRef();
  }

  static contextType = UserContext;

  componentDidUpdate (prevProps, prevState) {
    if (this.state.authState !== this.props.authState) {
      this.setState({
        prevAuthState: prevProps.authState,
        authState: this.props.authState
      });
      if (this.props.authState === 'signedIn') {
        this.setState({
          open: isXLarge() // isXLarge()
        });
      }
    }
  }

  async componentDidMount () {
    try {
      // eslint-disable-next-line no-undef
      self.window.name = 'myBuildsimple';
      // disable google translations (remove this as soon as react is stable with )
      const meta = document.createElement('meta');
      meta.setAttribute('name', 'google');
      meta.setAttribute('value', 'notranslate');
      document.getElementsByTagName('head')[0].appendChild(meta);
      document.getElementsByTagName('html')[0].setAttribute('translate', 'no');
      // end disable google translation
    } catch (error) {
      console.error('Could not set window name', error);
    }
    configureAmplify();
  }

  handleMenuButtonPressed () {
    this.setState({ open: !this.state.open });
    this.sideNavRef.current.toggleDrawer();
  }

  wizardClose () {
    this.setState({
      initialLogin: false
    });
  }

  render () {
    const { classes, authState } = this.props;

    // Just render App if user is signedIn
    const isLoggedIn = authState === 'signedIn';
    if (!isLoggedIn) {
      return null;
    }

    const isLoaded = this.context.isLoaded;
    const lang = navigator.language.toLowerCase().startsWith('de') ? 'de' : 'en';
    document.documentElement.lang = lang;

    if (isLoaded) {
      try {
        const iamRole = this.context.user.signInUserSession.idToken.payload['cognito:preferred_role'];
        const idxRole = this.context.user.signInUserSession.idToken.payload['cognito:roles'].indexOf(iamRole);
        this.userRole = this.context.user.signInUserSession.idToken.payload['cognito:groups'][idxRole];
        this.groups = this.context.user.signInUserSession.idToken.payload['cognito:groups'];
        this.allRoles = this.context.user.allRoles;
        this.featuresToShow = getFeaturesToShow(this.context);
        this.featuresToHide = getFeaturesToHide(this.context);
        this.routes = genrateRoutes(this.allRoles, this.featuresToShow, this.featuresToHide, this.context?.user?.organization, this.context?.customer?.activeFeatures);
      } catch (error) {
        this.userRole = this.context.user.attributes['custom:role'];
      }

      if (this.state.open) {
        document.body.classList.remove('closedDrawer');
        if (!document.body.classList.contains('openDrawer')) { document.body.classList.add('openDrawer'); }
      } else {
        document.body.classList.remove('openDrawer');
        if (!document.body.classList.contains('closedDrawer')) { document.body.classList.add('closedDrawer'); }
      }
    }

    if (!isLoaded) {
      return (<div className={'preLoader'}>
      <div>
        <div className="iconWrapper">
          <BuildsimpleIcon />
          <div className="shadow"/>
        </div>
        <div className="spinner">
          <div className="bounce1"/>
          <div className="bounce2"/>
          <div className="bounce3"/>
        </div>
      </div>
    </div>);
    }

    return (
      <>
        <div className={'root'} lang={navigator.language.toLowerCase().startsWith('de') ? 'de' : 'en'}>

          <Router>
            <NavigationBar title="" drawerWidth={drawerWidth} open={this.state.open} shift={this.state.shift} onMenuButtonPressed={this.handleMenuButtonPressed} ref={this.navBarRef} setSelectedProfile={this.props.setSelectedProfile}/>
            <SideNav ref={this.sideNavRef} open={this.state.open} hasVerifier={this.props.hasVerifier} routes={this.routes} user={this.context.user}
              intl={this.props.intl}
              onToggle={(drawerState) => this.setState({ open: drawerState })}
              onTypeChange={(shift) => this.setState({ shift })}
              navBar={this.navBarRef}
            />
            <NavbarContext.Provider value={{
              navBar: this.navBarRef.current,
              sideBar: this.sideNavRef.current
            }}>
              <main className={classes.content}>
                <div className={classes.toolbarSpacer} />
                <div>

                  <Suspense fallback={<LinearProgress />}>

                    <Routes>
                      {this.routes.map(routeRenderer(this.sideNavRef, this.navBarRef, this.state.authState, this.state.prevAuthState))}

                      <Route key="welcome" path="/" exact={false} element={<Navigate replace to="/home" />} />

                    </Routes>

                  </Suspense>
                </div>
              </main>
            </NavbarContext.Provider>
          </Router>
        </div>
      </>
    );
  }

}

function routeRenderer (sideBar, navBar, authState, prevAuthState) {
  const renderRoute = (route) => {
    const routeElem = withNavAndSideBar({ Component: route.element, sideBar, navBar, authState, prevAuthState });
    if (route.children) {
      return (
        <Route exact={route.exact} key={route.key} path={route.path} element={routeElem}>
          {route.children.map(renderRoute)}
        </Route>
      );
    }
    return (
      <Route exact={route.exact} key={route.key} path={route.path} element={routeElem}/>
    );
  };
  return renderRoute;
}

export default withStyles(styles)(injectIntl(App));
