import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import StatusIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ModelIcon from '@mui/icons-material/BlurOn';
import TechnicalAiViewIcon from '@mui/icons-material/BlurLinear';
import Business from '@mui/icons-material/Business';
import ModelBuilderIcon from '@mui/icons-material/Category'; // BlurOn, BubbleChart, Category, Widgets
import ApiDocuIcon from '@mui/icons-material/IntegrationInstructions';
import DeveloperIcon from '@mui/icons-material/DeveloperMode';
import IntegrationIcon from '@mui/icons-material/Tune';
import WebhookIcon from '@mui/icons-material/Webhook';
import DvrRoundedIcon from '@mui/icons-material/DvrRounded';
import CustomerIcon from '@mui/icons-material/SupervisorAccount';
import HomeIcon from '@mui/icons-material/Home';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Equalizer from '@mui/icons-material/Equalizer';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import WorldIcon from '@mui/icons-material/Public'; // Language
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ShopIcon from '@mui/icons-material/Shop';
import SupportIcon from '@mui/icons-material/Support';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MicrosoftIcon from '@mui/icons-material/Apps';
import { mdiCash, mdiCharity, mdiFinance, mdiSourceBranch, mdiSourcePull, mdiHandshake, mdiFileDocumentMultipleOutline, mdiFileChart, mdiDatabase } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import BuildsimpleIcon from './components/icon/Buildsimple';
import Verify from './components/icon/Verify';
import ProfileIcon from '@mui/icons-material/Storage';
import { getFeaturesToShow } from './data/Organization';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Outlet } from 'react-router-dom';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import InfoIcon from '@mui/icons-material/Info';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import { Inbox } from '@mui/icons-material';

const QualityIcon = React.forwardRef(function MdiIcon (props, ref) { return (<Icon {...props} ref={ref} path={mdiFinance } className='MuiSvgIcon-root' />); });
const CashIcon = React.forwardRef(function MdiIcon (props, ref) { return (<Icon {...props} ref={ref} path={mdiCash } className='MuiSvgIcon-root' />); });
export const WorkflowIcon = React.forwardRef(function MdiIcon (props, ref) { return (<Icon {...props} ref={ref} path={mdiSourceBranch } className='MuiSvgIcon-root' />); });
const CharityIcon = React.forwardRef(function MdiIcon (props, ref) { return (<Icon {...props} ref={ref} path={mdiCharity } className='MuiSvgIcon-root' />); });
const InterfaceIcon = React.forwardRef(function MdiIcon (props, ref) { return (<Icon {...props} ref={ref} path={mdiSourcePull } className='MuiSvgIcon-root' />); });
export const PartnerIcon = React.forwardRef(function MdiIcon (props, ref) { return (<Icon {...props} ref={ref} path={mdiHandshake } className='MuiSvgIcon-root' />); });
const DocumentsIcon = React.forwardRef(function MdiIcon (props, ref) { return (<Icon {...props} ref={ref} path={mdiFileDocumentMultipleOutline } className='MuiSvgIcon-root' />); });
const DocumentsDashboardIcon = React.forwardRef(function MdiIcon (props, ref) { return (<Icon {...props} ref={ref} path={mdiFileChart } className='MuiSvgIcon-root' />); });
const MasterDataIcon = React.forwardRef(function MdiIcon (props, ref) { return (<Icon {...props} ref={ref} path={mdiDatabase } className='MuiSvgIcon-root' />); });

/*
const AtomIcon = React.forwardRef(function MdiIcon (props, ref) { return (<Icon {...props} ref={ref} path={mdiAtom } className='MuiSvgIcon-root' />); });
const AutoFixIcon = React.forwardRef(function MdiIcon (props, ref) { return (<Icon {...props} ref={ref} path={mdiAutoFix } className='MuiSvgIcon-root' />); });
*/

/**
 * Checkout React.lazy
 * https://reactjs.org/docs/code-splitting.html
 *
 * The React.lazy function lets you render a dynamic import as a regular component.
 * This will automatically load the bundle containing the companent when this component is first rendered.
 */

const OldSubscriptions = React.lazy(() => import('./components/services/OldSubscriptions'));
const Subscriptions = React.lazy(() => import('./components/subscriptions/Subscriptions'));
const Profiles = React.lazy(() => import('./components/profiles/Profiles'));
const BSUserManagement = React.lazy(() => import('./components/admin/BSUserManagement'));
const BSStatistics = React.lazy(() => import('./components/statistics_dashboard/BSStatistics'));
const PipelineDashboard = React.lazy(() => import('./components/pipeline/dashboard/PipelinesDashboard'));
const BSAccount = React.lazy(() => import('./components/account/BSAccount'));
const Organization = React.lazy(() => import('./components/organization/Organization'));
const Contracts = React.lazy(() => import('./components/organization/Contracts'));
const Home = React.lazy(() => import('./components/home/Home.js'));
const VerifierInbox = React.lazy(() => import('./components/verifier/Inbox.js'));
const HomeNew = React.lazy(() => import('./components/home/HomeNew.js'));
const Operation = React.lazy(() => import('./components/operation/Operation'));
const CustomersAdmin = React.lazy(() => import('./components/customers/CustomersAdmin'));
const Administration = React.lazy(() => import('./components/operation/Administration'));
const BSSettings = React.lazy(() => import('./components/BSSettings.js'));
const Shop = React.lazy(() => import('./components/shop/Shop'));
const Pipelines = React.lazy(() => import('./components/pipeline/Pipelines'));
const DocumentStatus = React.lazy(() => import('./components/operation/Documents'));
const CostDashboard = React.lazy(() => import('./components/cost_dashboard/Dashboard.js'));
const AvailabilityDashboard = React.lazy(() => import('./components/availability_dashboard/Dashboard.js'));
const Modelbuilder = React.lazy(() => import('./components/modelbuilder/Modelbuilder.js'));
const Models = React.lazy(() => import('./components/modelbuilder/Models.js'));
const ApiDescription = React.lazy(() => import('./components/developer/ApiDescription.js'));
const ApiDescriptionPipeline = React.lazy(() => import('./components/developer/pipeline/ApiDescription.js'));
const ApiDescriptionMasterdata = React.lazy(() => import('./components/developer/masterdata/ApiDescription.js'));
const IntegrationOptions = React.lazy(() => import('./components/developer/IntegrationOptions.js'));
const Webhooks = React.lazy(() => import('./components/webhooks/Webhooks.js'));
const TechnicalModelsView = React.lazy(() => import('./components/developer/TechnicalModelsView.js'));
const TechnicalPipelinesView = React.lazy(() => import('./components/developer/TechnicalPipelinesView.js'));
const MasterData = React.lazy(() => import('./components/masterdata/MasterData'));
const Partners = React.lazy(() => import('./components/partners/Partners.js'));
const TrainingIntervals = React.lazy(() => import('./components/operation/TrainingIntervals'));
const Charity = React.lazy(() => import('./components/bsworld/Charity'));
const BuildsimpleConnect = React.lazy(() => import('./components/interface/BuildsimpleConnect.js'));
const Email = React.lazy(() => import('./components/interface/email/Email.js'));
const Office = React.lazy(() => import('./components/interface/Office.js'));
const About = React.lazy(() => import('./components/about'));
const ContractTemplates = React.lazy(() => import('./components/operation/contractTemplates/ContractTemplates'));
const Updates = React.lazy(() => import('./components/product_updates/Updates'));

const routes = [
  {
    key: 'home',
    path: '/home',
    to: '/home',
    text: 'home.title',
    icon: <BuildsimpleIcon />,
    element: Home,
    roles: ['TenantAdmin', 'TenantQualityControlling', 'TenantCostControlling', 'TenantUser'],
    exact: true
  },
  {
    key: 'homeNew',
    path: '/homeNew',
    to: '/homeNew',
    text: 'home.title',
    icon: <HomeIcon />,
    element: HomeNew,
    roles: ['Operator'],
    exact: true,
    hidden: true
  },
  {
    key: 'verifier',
    path: 'https://verifier.buildsimple.de',
    to: 'https://verifier.buildsimple.de',
    text: 'service.label.dv',
    icon: <Verify fill='white' width='30px' height='30px' className='service' />,
    roles: ['VerifierSplitting', 'VerifierEntityExtraction', 'VerifierClassification'],
    exact: true
  },
  {
    key: 'inbox',
    path: '/inbox/*',
    to: '/inbox',
    text: 'verification.title',
    icon: <Inbox />,
    element: VerifierInbox,
    roles: ['Operator', 'Support'],
    exact: true
  },
  {
    key: 'dashboards',
    path: '/dashboards/*',
    to: '/dashboards',
    text: 'dashboards.title',
    icon: <Equalizer />,
    element: Outlet,
    roles: ['TenantCostControlling', 'TenantQualityControlling', 'AIDesigner'],
    exact: true,
    children: [
      {
        key: 'costs',
        path: 'costs',
        to: '/dashboards/costs',
        text: 'costdashboard.title',
        icon: <CashIcon />,
        element: CostDashboard,
        roles: ['TenantCostControlling'],
        exact: true
      },
      {
        key: 'statistics',
        path: 'statistics',
        to: '/dashboards/statistics',
        text: 'statistics.title',
        icon: <QualityIcon />,
        element: BSStatistics,
        roles: ['TenantQualityControlling'],
        exact: true
      },
      {
        key: 'pipelinesDashboard',
        path: 'pipelines/*',
        to: '/dashboards/pipelines',
        text: 'pipelinesDashboard.title',
        icon: <DocumentsDashboardIcon />,
        element: PipelineDashboard,
        roles: ['AIDesigner'],
        exact: false,
        featureFlags: ['PIPELINE']
      },
      {
        key: 'availability',
        path: 'availability',
        to: '/dashboards/availability',
        text: 'availability.title',
        icon: <AccessTimeIcon />,
        element: AvailabilityDashboard,
        roles: ['TenantAdmin'],
        exact: true
      }
    ]
  },
  {
    key: 'aisolutions',
    path: 'aisolutions/*',
    to: '/aisolutions',
    text: 'aispace.title',
    icon: <ModelIcon />,
    iconComponent: ModelIcon,
    element: Models,
    roles: ['AIDesigner'],
    exact: false
  },
  {
    key: 'newaisolution',
    path: 'aisolutions/new',
    to: '/aisolutions/new',
    text: 'modelbuilder.title',
    icon: <ModelBuilderIcon />,
    iconComponent: ModelBuilderIcon,
    element: Modelbuilder,
    roles: ['AIDesigner'],
    exact: true,
    hidden: true
  },
  {
    key: 'pipelines',
    path: 'pipelines/*',
    to: '/pipelines',
    text: 'pipelines.title',
    icon: <WorkflowIcon />,
    iconComponent: WorkflowIcon,
    element: Pipelines,
    roles: ['AIDesigner'],
    exact: true
  },
  {
    key: 'marketplace',
    path: 'marketplace/*',
    to: '/marketplace',
    text: 'shop.title',
    icon: <ShopIcon />,
    iconComponent: ShopIcon,
    element: Shop,
    roles: ['AIDesigner'],
    exact: false
  },
  {
    key: 'partners',
    path: '/partners',
    to: '/partners',
    text: 'partners.title',
    icon: <PartnerIcon />,
    element: Partners,
    roles: ['TenantAdmin'],
    exact: true
  },
  {
    key: 'developers',
    path: '/dev/*',
    to: '/dev',
    text: 'developers.title',
    icon: <DeveloperIcon />,
    element: Outlet,
    roles: ['TenantAdmin', 'Development'],
    exact: true,
    children: [
      {
        key: 'integration',
        path: 'integration',
        to: '/dev/integration',
        text: 'integration.title',
        icon: <IntegrationIcon />,
        element: IntegrationOptions,
        roles: ['TenantAdmin', 'Development'],
        exact: true
      },
      {
        key: 'apiDoc',
        path: 'apiDoc',
        to: '/dev/apiDoc',
        text: 'apiDoc.title',
        icon: <ApiDocuIcon />,
        element: ApiDescription,
        roles: ['TenantAdmin', 'Development'],
        exact: true
      },
      {
        key: 'pipelineDoc',
        path: 'pipelineDoc',
        to: '/dev/pipelineDoc',
        text: 'apiDocPipelines.title',
        icon: <ApiDocuIcon />,
        element: ApiDescriptionPipeline,
        roles: ['TenantAdmin', 'Development'],
        exact: true,
        featureFlags: ['PIPELINE']
      },
      {
        key: 'masterdataDoc',
        path: 'masterdataDoc',
        to: '/dev/masterdataDoc',
        text: 'apiDocMasterdata.title',
        icon: <ApiDocuIcon />,
        element: ApiDescriptionMasterdata,
        roles: ['TenantAdmin', 'Development'],
        exact: true,
        featureFlags: ['MASTER_DATA']
      },
      {
        key: 'webhooks',
        path: 'webhooks',
        to: '/dev/webhooks',
        text: 'webhooks.title',
        icon: <WebhookIcon />,
        element: Webhooks,
        roles: ['Operator'],
        exact: true
      },
      {
        key: 'technicalModelsView',
        path: 'models/*',
        to: '/dev/models',
        text: 'models.technicalView.title',
        icon: <TechnicalAiViewIcon />,
        iconComponent: TechnicalAiViewIcon,
        element: TechnicalModelsView,
        roles: ['TenantAdmin', 'Development'],
        exact: true
      },
      {
        key: 'technicalPipelines',
        path: 'pipelines/*',
        to: '/dev/pipelines',
        text: 'pipelines.technical.title',
        icon: <WorkflowIcon />,
        element: TechnicalPipelinesView,
        roles: ['TenantAdmin', 'Development'],
        exact: true,
        featureFlags: ['PIPELINE']
      }
    ]
  },
  {
    key: 'administration',
    path: '/administration/*',
    to: '/administration',
    text: 'administration.title',
    icon: <SettingsIcon />,
    element: Outlet,
    roles: ['TenantAdmin'],
    exact: true,
    children: [
      {
        key: 'subs',
        path: 'subs',
        to: '/administration/subs',
        text: 'subs.title',
        icon: <AppsRoundedIcon />,
        element: Subscriptions,
        roles: ['TenantAdmin'],
        exact: true,
        hidePerDefault: true
      },
      {
        key: 'subsOld',
        path: 'subsOld',
        to: '/administration/subsOld',
        text: 'subs.title',
        icon: <AppsRoundedIcon />,
        element: OldSubscriptions,
        roles: ['TenantAdmin'],
        exact: true
      },
      {
        key: 'profiles',
        path: 'profiles',
        to: '/administration/profiles',
        text: 'profiles.title',
        icon: <ProfileIcon />,
        element: Profiles,
        roles: ['TenantAdmin'],
        exact: true
      },
      {
        key: 'users',
        path: 'users',
        to: '/administration/users',
        text: 'usermanagement.title',
        icon: <SupervisorAccountRoundedIcon />,
        element: BSUserManagement,
        roles: ['TenantAdmin'],
        exact: false
      },
      {
        key: 'customers',
        path: 'customers/*',
        to: '/administration/customers',
        text: 'customers.title',
        icon: <ManageAccountsIcon />,
        element: CustomersAdmin,
        roles: ['TenantAdmin'],
        exact: false
      },
      {
        key: 'organization',
        path: 'organization',
        to: '/administration/organization',
        text: 'organization.title',
        icon: <Business/>,
        element: Organization,
        roles: ['TenantAdmin'],
        exact: false
      },
      {
        key: 'contracts',
        path: 'contracts',
        to: '/administration/contracts',
        text: 'contract.label',
        icon: <DocumentsIcon/>,
        element: Contracts,
        roles: ['TenantAdmin'],
        exact: false
      },
      {
        key: 'masterdata',
        path: 'masterdata/*',
        to: '/administration/masterdata',
        text: 'masterData.title',
        icon: <MasterDataIcon />,
        element: MasterData,
        roles: ['TenantAdmin'],
        featureFlags: ['MASTER_DATA'],
        exact: false
      },
      {
        key: 'interfaces',
        path: 'interfaces/*',
        to: '/administration/interfaces',
        text: 'interface.title',
        icon: <InterfaceIcon/>,
        element: Outlet,
        exact: true,
        children: [
          {
            key: 'connect',
            path: 'connect',
            to: '/administration/interfaces/connect',
            text: 'connect.title',
            icon: <CloudSyncIcon/>,
            element: BuildsimpleConnect,
            roles: ['Operator', 'Support'],
            exact: true
          },
          {
            key: 'mail',
            path: 'mail',
            to: '/administration/interfaces/mail',
            text: 'mail.title',
            icon: <MailOutlineIcon/>,
            element: Email,
            exact: true
          },
          {
            key: 'office',
            path: 'office',
            to: '/administration/interfaces/office',
            text: 'office.title',
            icon: <MicrosoftIcon/>,
            element: Office,
            roles: ['Operator', 'Support'],
            exact: true
          }
        ]
      },
      {
        key: 'helpdesk',
        path: 'https://buildsimple.atlassian.net/servicedesk/customer',
        to: 'https://buildsimple.atlassian.net/servicedesk/customer',
        text: 'about.servicedesk',
        icon: <SupportIcon />,
        roles: ['TenantAdmin'],
        exact: true
      },
      {
        key: 'settings',
        path: 'settings',
        to: '/administration/settings',
        text: 'settings.title',
        icon: <SettingsIcon />,
        element: BSSettings,
        roles: ['TenantAdmin'],
        exact: true
      }
    ]
  },
  {
    key: 'productUpdates',
    path: 'productUpdates',
    to: '/productUpdates',
    text: 'productUpdates.title',
    icon: <BrowserUpdatedIcon/>,
    element: Updates,
    roles: ['TenantUser'],
    exact: false,
    hidden: true
  },
  {
    key: 'account',
    path: '/account/*',
    to: '/account',
    text: 'account.title',
    icon: <SettingsRoundedIcon />,
    element: BSAccount,
    roles: ['TenantAdmin', 'TenantQualityControlling', 'TenantCostControlling', 'TenantUser'],
    exact: false,
    hidden: true
  },
  {
    key: 'about',
    path: '/about/*',
    to: '/about',
    text: 'about.title',
    icon: <InfoIcon />,
    element: About,
    roles: ['TenantUser'],
    exact: false,
    hidden: true
  },
  {
    key: 'bsWorld',
    path: '/bsworld',
    to: '/bsWorld',
    text: 'bsWorld.title',
    icon: <WorldIcon />,
    element: Outlet,
    roles: ['Operator', 'Support'],
    exact: false,
    children: [
      {
        key: 'charity',
        path: 'charity',
        to: '/bsworld/charity',
        text: 'charity.title',
        icon: <CharityIcon />,
        element: Charity,
        roles: ['Operator', 'Support'],
        exact: true
      }
    ]
  },
  {
    key: 'operator',
    path: '/operator/*',
    to: '/operator',
    text: 'operator.title',
    icon: <DvrRoundedIcon />,
    element: Outlet,
    roles: ['Operator', 'Support'],
    exact: false,
    children: [
      {
        key: 'tenants',
        path: 'tenants/*',
        to: '/operator/tenants',
        text: 'operator.tenants',
        icon: <CustomerIcon />,
        element: Operation,
        roles: ['Operator', 'Support'],
        exact: false
      },
      {
        key: 'contractTemplates',
        path: 'contractTemplates',
        to: '/operator/contractTemplates',
        text: 'operator.contractTemplates',
        icon: <DocumentsIcon />,
        element: ContractTemplates,
        roles: ['Operator'],
        exact: false
      },
      {
        key: 'trainingintervals',
        path: 'trainingIntervals',
        to: '/operator/trainingIntervals',
        text: 'operator.trainingIntervals',
        icon: <ModelTrainingIcon />,
        element: TrainingIntervals,
        roles: ['Operator'],
        exact: false
      },
      {
        key: 'status',
        path: 'status',
        to: '/operator/status',
        text: 'status.title',
        icon: <StatusIcon />,
        element: DocumentStatus,
        roles: ['Operator'],
        exact: true
      },
      {
        key: 'operator/administration',
        path: 'administration',
        to: '/operator/administration',
        text: 'operator.administration',
        icon: <AppsRoundedIcon />,
        element: Administration,
        roles: ['Operator'],
        exact: true
      }
    ]
  }
];

export const isAppVisibleForUser = function (routeId, userGroups) {
  const route = getRoute(routeId);
  if (route?.roles) {
    return !isEmpty(intersection(userGroups, route.roles));
  }
  return false;
};

export const genrateRoutes = function (userGroups, featuresToShow, featuresToHide, organization, featureFlags) {
  return filterRoutes(routes, userGroups, featuresToShow, featuresToHide, organization, featureFlags);
};

const filterRoutes = function (routes, userGroups, featuresToShow, featuresToHide, organization, featureFlags) {
  const userRoutes = [];

  // if usergroups are not set, only generate Routes with no groups props
  if (!userGroups) {
    routes.forEach((route) => {
      if (!route.roles) {
        const newRoute = { ...route };
        if (newRoute.children) {
          newRoute.children = filterRoutes(newRoute.children, userGroups, featuresToShow, featuresToHide, organization, featureFlags);
        }
        userRoutes.push(newRoute);
      }
    });
  } else {
    // generate Routes with matching group prop
    routes.forEach((route) => {
      if (hasNoFeatureFlagOrAllowedByFeatureFlag(route, featureFlags) && isAllowedByOrganizationAttribute(route, organization)) {
        if (!route.roles || route.roles.filter((role) => userGroups.includes(role) || (role.startsWith('Verifier') && userGroups.find(group => group.endsWith(':' + role)))).length > 0 || (featuresToShow && featuresToShow.includes(route.key))) {
          if (!userRoutes.includes(route) && !(featuresToHide && featuresToHide.includes(route.key))) {
            const routeToAdd = { ...route };
            userRoutes.push(routeToAdd);
            if (routeToAdd.children) {
              routeToAdd.children = filterRoutes(routeToAdd.children, userGroups, featuresToShow, featuresToHide, organization, featureFlags);
            }
          }
        }
      }
      if (route.hidePerDefault === true) {
        route.hidden = !(featuresToShow && featuresToShow.includes(route.key));
      }
    });
  }
  return userRoutes;
};

export const hasPermission = function (context, routeKey) {
  const groups = context && context.user && context.user.signInUserSession && context.user.signInUserSession.idToken ? context.user.signInUserSession.idToken.payload['cognito:groups'] : undefined;
  const featuresToShow = getFeaturesToShow(context);
  const route = getRoute(routeKey);
  return !!(route && (!route.roles || route.roles.find(role => groups.includes(role) || (featuresToShow && featuresToShow.includes(routeKey)))));
};

/**
 *
 * @param route
 * @param {String[]} flags
 * @returns {boolean}
 */
export const hasNoFeatureFlagOrAllowedByFeatureFlag = function (route, flags) {
  if (!route.featureFlags) { return true; }
  return route.featureFlags.some(flag => flags?.includes(flag));
};

export const isAllowedByOrganizationAttribute = function (route, organization) {
  if (route.key === 'customers') {
    return organization.customerType === 'PARTNER';
  }
  return true;
};

export function getRoute (key, _routes = routes) {
  for (const route of _routes) {
    if (route.key === key) {
      return route;
    }
    if (route.children) {
      const found = getRoute(key, route.children);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

function findPath (routes, key, path = '') {
  for (const route of routes) {
    const newPath = path + '/' + route.path.replace('/*', '');
    if (route.key === key) {
      return newPath;
    }
    if (route.children) {
      const childPath = findPath(route.children, key, newPath);
      if (childPath) {
        return childPath;
      }
    }
  }
  return null;
}

export function getRoutePath (key) {
  return findPath(routes, key);
}

export default routes;
